import { TIMEOUT, ERR_OK, baseURL, STATUS } from './config'
import axios from "axios"
import qs from 'qs'


// 将中文转换为 ASCII 码
const handleChinese = (params) => {
	const pattern = new RegExp('[\u4E00-\u9FA5]+') // 正则匹配中文字符
	let newObj = {}
	for(let i in params) {
		if(pattern.test(params[i])) {
			newObj[i] = encodeURI(params[i])
		} else {
			newObj[i] = params[i]
		}
	}
	return newObj
}

const instance = axios.create({
	timeout: 50000,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	}
});

// 请求拦截器 
instance
	.interceptors
	.request
	.use((config) => {
		if(config.method === 'post') {
			//'application/x-www-form-urlencoded'
			//console.log(config)
			// if(config.url == baseURL+'/api/moduleInfo/info'){
			// 	config.headers['Content-Type'] = "application/json; charset=UTF-8";
			// }else{
				config.data = qs.stringify(config.data)
			// }
			return config
		}
		if(config.method === 'get') {
			// let params = null
			// // 将中文编码
			// params = handleChinese(config.params)
			// config.url += '?' + qs.stringify(params, {
			// 	encode: false
			// })
			// config.params = {}
			return config
		}
	}, (error) => {
		return Promise.reject(error)
	})
// 添加响应拦截器
instance
	.interceptors
	.response
	.use((response) => {
		if(response.data.code == 401) {
			console.error('用户认证过期，需要重新登录！')
		}
		return response
	}, (err) => {
		return Promise.reject(err)
	})

const xhr = {
	post(config) {
		return new Promise((resolve, reject) => {
			instance
				.post(`${config.url}`, config.params)
				.then(res => resolve(res.data))
				.catch(err => reject(err))
		})
	},
	get(config) {
		return new Promise((resolve, reject) => {
			instance
				.get(`${config.url}`, {
					params: config.params})
				.then((res => resolve(res.data)))
				.catch(err => reject(err))
		})
	}
}
export default xhr