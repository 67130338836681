import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 合作伙伴列表
 */
export const selectPartnerList = (params) => {
    return $api.post({
        url: baseURL + '/api/cms/selectPartnerList',
        params
    })
}

/**
 * 招聘列表
 */
 export const selectWantInfoList = (params) => {
    return $api.post({
        url: baseURL + '/api/cms/selectWantInfoList',
        params
    })
}

/**
 * 招聘详情
 */
 export const wantInfoDetail = (params) => {
    return $api.get({
        url: baseURL + '/api/cms/wantInfoDetail',
        params
    })
}

/**
 * 新闻列表
 */
 export const selectNewsList = (params) => {
    return $api.post({
        url: baseURL + '/api/cms/selectNewsList',
        params
    })
}

/**
 * 新闻详情
 */
 export const newsDetail = (params) => {
    return $api.get({
        url: baseURL + '/api/cms/newsDetail',
        params
    })
}

/**
 * 大事记列表
 */
 export const mentlist = (params) => {
    return $api.post({
        url: baseURL + '/api/list',
        params
    })
}

/**
 * 文章列表(董事长致辞、集团简介、集团业务、核心产品等)
 */
 export const articleList = (params) => {
    return $api.post({
        url: baseURL + '/api/articleList',
        params
    })
}


/**
 * 大事记列表
 */
export const search = (params) => {
    return $api.post({
        url: baseURL + '/api/search',
        params
    })
}

/**
 * 获取多个素材接口，比如首页banner
 */
 export const selectMaterialList = (params) => {
    return $api.post({
        url: baseURL + '/api/cms/selectMaterialList',
        params
    })
}

/**
 * footer下分公司切换
 */
export const companyList = (params) => {
    return $api.post({
        url: baseURL + '/api/companyList',
        params
    })
}

/**
 * 首页bannner、集团业务、加入我们查询
 */
export const selectIndexMaterials = (params) => {
    return $api.post({
        url: baseURL + '/api/cms/selectIndexMaterials',
        params
    })
}

/**
 * 企业文化
 */
 export const selectCorporateCultures = (params) => {
    return $api.get({
        url: baseURL + '/api/cms/selectCorporateCultures',
        params
    })
}